import * as React from 'react';

export const upArrow = (
  <svg id="arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.46 238.93">
    <path
      d="M211.31,107.29,32.4,312.09a18.87,18.87,0,0,0,0,24.14,13.62,13.62,0,0,0,21.08,0L221.85,143.49,390.22,336.23a13.61,13.61,0,0,0,20.72,0,18.87,18.87,0,0,0,.36-24.14L232.39,107.29A13.62,13.62,0,0,0,211.31,107.29Z"
      transform="translate(-28.03 -102.3)"
    />
  </svg>
);

export const downArrow = (
  <svg id="arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.46 238.93">
    <path
      d="M232.21,336.23l178.91-204.8a18.87,18.87,0,0,0,0-24.14,13.62,13.62,0,0,0-21.08,0L221.67,300,53.3,107.29a13.61,13.61,0,0,0-20.72,0,18.87,18.87,0,0,0-.36,24.14l178.91,204.8A13.62,13.62,0,0,0,232.21,336.23Z"
      transform="translate(-28.03 -102.3)"
    />
  </svg>
);

export const rightArrow = (
  <svg id="arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.93 387.46">
    <path
      d="M336.23,211.31,131.43,32.4a18.87,18.87,0,0,0-24.14,0,13.62,13.62,0,0,0,0,21.08L300,221.85,107.29,390.22a13.61,13.61,0,0,0,0,20.72,18.87,18.87,0,0,0,24.14.36l204.8-178.91A13.62,13.62,0,0,0,336.23,211.31Z"
      transform="translate(-102.3 -28.03)"
    />
  </svg>
);

export const leftArrow = (
  <svg id="arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.93 387.46">
    <path
      d="M107.29,232.21l204.8,178.91a18.87,18.87,0,0,0,24.14,0,13.62,13.62,0,0,0,0-21.08L143.49,221.67,336.23,53.3a13.61,13.61,0,0,0,0-20.72,18.87,18.87,0,0,0-24.14-.36L107.29,211.13A13.62,13.62,0,0,0,107.29,232.21Z"
      transform="translate(-102.3 -28.03)"
    />
  </svg>
);

export default {
  upArrow,
  downArrow,
  rightArrow,
  leftArrow,
};
